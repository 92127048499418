var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12" }, [
    _c(
      "div",
      {
        staticClass: "py-4 px-2",
        style: { backgroundColor: "rgba(0,0,0,0.3)" },
      },
      [
        _c("p", { staticClass: "text-center spainhn-subtitle-2-black" }, [
          _vm._v(_vm._s(_vm.titulo)),
        ]),
        _c(
          "div",
          { staticClass: "w-100 d-flex" },
          [
            _c(
              "mdb-btn",
              {
                staticClass: "spainhn-button mx-auto w-75 py-3 spainhn-text",
                staticStyle: { "font-size": "16pt" },
                attrs: { color: "indigo", block: "", href: _vm.url },
                on: {
                  click: () =>
                    _vm.goTo
                      ? _vm.$router.push(_vm.goTo)
                      : _vm.goToLogin(_vm.url),
                },
              },
              [_vm._v(_vm._s(_vm.buttonText))]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }