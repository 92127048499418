<template>    
    <div class="col-12">
        <div :style="{backgroundColor:'rgba(0,0,0,0.3)'}" class="py-4 px-2">
            <p class="text-center spainhn-subtitle-2-black">{{titulo}}</p>
            <div class="w-100 d-flex">
                <mdb-btn color="indigo" class="spainhn-button mx-auto w-75 py-3 spainhn-text" style="font-size:16pt" block :href="url" @click="() => goTo ? $router.push(goTo): goToLogin(url)">{{buttonText}}</mdb-btn>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    name: "LoginItem",
    components:{

    },
    props:{
        titulo: String,
        url: String,
        buttonText: String,
        goTo: String
    },
    data(){
        return{
            
        }
    },
    methods:{
        goToLogin(){
            window.location.assign(this.url);
        }
    }
}
</script>